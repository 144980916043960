<template>
  <s-dialog
    :visible="visible"
    :append-to-body="true"
    :show-close="true"
    class="image-view-dialog"
    type="W720"
    @open="onOpen"
    @update:visible="updateVisible"
  >
    <image-view-swiper
      ref="swiper"
      :language="language"
      :urls="urls"
    />
  </s-dialog>
</template>

<script>
import ImageViewSwiper from './ImageViewSwiper.vue'

export default {
  name: 'ImageViewDialog',
  emits: ['update:visible'],
  components: {
    ImageViewSwiper,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => {},
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    urls: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    slideToIndex() {
      this.$refs.swiper?.slideToIndex(this.activeIndex)
    },
    onOpen() {
      if (this.$refs.swiper) {
        this.slideToIndex()
      } else {
        this.$nextTick(() => this.slideToIndex())
      }
    },
  },
}
</script>

<style lang="less">
.image-view-dialog {
  .sui-dialog__wrapper .sui-dialog__body {
    padding: 0 29px;
  }
}
</style>
