import { createApp } from 'vue'
import CustomContentApp from './CustomContentDialog.vue'
import { registerBaseContext } from '../../common/common_inject'

let customContentInstance = undefined

const CustomContentBox = () => {
  if (customContentInstance) return customContentInstance
  const tempDiv = document.createElement('div')
  const instance = createApp(CustomContentApp)
  registerBaseContext(instance)
  document.body.appendChild(tempDiv)
  const vm = instance.mount(tempDiv)
  customContentInstance = vm
  return vm
}
/**
 * @description: 打开自定义素材弹窗
 * @param {Object} options - 自定义素材弹窗配置
 * @param {Object} options.data - 定制商品信息
 * @param {Function} options.open - 打开素材弹窗回调
 * @param {Function} options.close - 关闭素材弹窗回调
 * @param {Function} options.preview - 点击预览素材回调
 * @param {Object} options.analysisData - 埋点的额外参数
 * @return {Object}
 */
CustomContentBox.open = (options = {}) => {
  const instance = CustomContentBox()
  instance.open(options)
  return instance
}

/**
 * @description: 关闭自定义素材弹窗
 */
CustomContentBox.close = () => {
  customContentInstance?.close()
}

export default CustomContentBox
